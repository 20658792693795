@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
    background: linear-gradient(to right, #42A6E3 0%, #79cbca80 51%, #FF56F6 100%);
    box-shadow: 0px 4.42184px 107.23px rgba(255, 86, 246, 0.51);
}
.btn:hover {
    background: linear-gradient(to right, #FF56F6 0%, #79cbca80 51%, #42A6E3 100%);
}

.gradient, .active {
    background: linear-gradient(#00356B, #7597de);
    
}
.active {
    color: #fff;
    padding: 1rem;
    border-radius: 100%;
} 
@layer base {
    body {
        @apply font-secondary text-lg leading-8 text-white;
    }
    .h2 {
        @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
    }
    .h3 {
        @apply font-primary font-semibold text-[26px] mb-6 leading-[46px];
    }
    .btn {
        @apply rounded-full font-primary text-white font-medium ;
    }
    .btn-sm {
        @apply h-[48px] px-6 text-sm;
    }
    .btn-lg {
        @apply h-[56px] px-10 text-base;
    }
    .btn-link {
        @apply text-base;
    }

    .text-gradient {
        @apply bg-gradient-to-r text-transparent bg-clip-text from-[#42A6E3] to-[#FF56F6] font-bold font-primary hover:from-[#FF56F6] hover:to-[#42A6E3];
    }
    .section {
        @apply py-8 lg:py-24 lg:h-screen flex items-center lg:items-start;
    }
    .theColorOfText {
        @apply bg-gradient-to-r text-transparent bg-clip-text from-[#FF56F6] to-[#055eb8] font-bold font-primary; 
    }
};
